
import React, { Component } from 'react';
import Password from './../common/Password';
import FormValidComment from './FormValidComment';
import FormPasswordStrength from './FormPasswordStrength';

export class FormPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStrength: false,
    };

    this.gotFocus = this.gotFocus.bind(this);
    this.lostFocus = this.lostFocus.bind(this);
  }

  gotFocus = e => {
    this.setState({
      showStrength: true
    });
  }

  lostFocus = e => {
    this.setState({
      showStrength: false
    });
  }

  render() {
    const { showLabel,ariaLabel,name,placeholder,onChange,type,values,valid,showValidation,defaultValue} = this.props;
    const {showStrength} = this.state;
    return (
      <>
        <div className="FormInput">
          {showLabel ? (
          <div className="formLabel">
              {ariaLabel}
          </div>
          ) : (
              <></>
          ) }
        
          <div className="form-input password" >
              <Password
                ariaLabel={ariaLabel}
                name={name}
                type={type}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                onFocus={this.gotFocus}
                onBlur={this.lostFocus}
              />
          
          </div>
          {showValidation ? (
            <>
              <FormValidComment 
                  show={showStrength}
                >
                <FormPasswordStrength values={values} />

              </FormValidComment> 
            </> ) : (<></>)}
          {!showStrength ? (
            <>
              <FormValidComment 
                  show={!valid}
                >Password not valid!</FormValidComment>
            </> ) : (<></>)}
              
            
          
        </div>
      </>
    )
  }
}

export default FormPassword
