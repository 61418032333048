import React from 'react';
import useWindowDimensions from './../utils/windowDimensions';
import defaultLoadingGif from './../images/loading-animation.gif';


const Loading = () => {
    const { height} = useWindowDimensions();
    const top = (height/2)-50;
    return (
      <div className="loadingWrap">
        <img src={defaultLoadingGif} alt="Loading Page" style={{marginTop: top + 'px'}}  />
      </div>
    )
  }

// export { Loading };
export default Loading;
