import React, { useContext } from 'react';
import {AuthContext} from './../context/AuthContext';
import Hyperlink from './common/Hyperlink';

import logo from './../images/mediavax-logo2.png';

const Header = () => {
  const authContext = useContext(AuthContext);
  let showLogo = authContext.isAuthenticated() ? logo : logo
  return (
    <header className="header white-text">
        <div className="mobile-container">
            <div className="header-logo">
                <Hyperlink to="/" >
                    <img className="w-32 h-full" src={showLogo} alt="Logo" />
                </Hyperlink>
            </div>
            
      </div>
    </header>
  );
};

export default Header;