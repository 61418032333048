import React from 'react';
import { Link } from 'react-router-dom';

const Hyperlink = ({ children, to, className}) => (
  <Link
    to={to}
    className={className}
  >
    {children}
    
  </Link>
);

export default Hyperlink;
