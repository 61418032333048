import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {

  const history = useHistory();
  const cookies = new Cookies();
  const token = cookies.get('spacerace');
  const userInfo = localStorage.getItem('userInfo');
  const expiresAt = localStorage.getItem('expiresAt');


  const  [authState,setAuthState] = useState({
      token: token ? token : '',
      expiresAt: expiresAt ? expiresAt : '',
      userInfo: userInfo ? JSON.parse(userInfo) : {},
  });


  const setAuthInfo = (data) => {
    // cookies.set('spacerace', token, { path: '/',htmlOnly: true, });
    
    const {token,userInfo,expiresAt} = data;
    console.log({token,userInfo,expiresAt});
    cookies.set('spacerace', token, { path: '/',htmlOnly: true, });
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    localStorage.setItem('expiresAt', expiresAt);
    setAuthState({
      token,
      userInfo,
      expiresAt
    });
    
  }

  const logout = () => {
    cookies.remove('spacerace');
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('expiresAt');
    setAuthState({
      token: null,
      expiresAt: null,
      userInfo: {}
    });
    history.push('/');
  }

  const isAuthenticated = () => {
    if (!authState.token || !authState.expiresAt) {
      return false;
    }
    return new Date().getTime() / 1000 < authState.expiresAt;

  }

  const isAdmin = () => {
    return authState.userInfo.role === 'admin';
  }

  return (
    <Provider
      value={{
        authState, 
        setAuthState : authInfo => setAuthInfo(authInfo),
        isAuthenticated,
        logout,
        isAdmin
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };