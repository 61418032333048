import React, { Component } from 'react'

export class Form extends Component {
    constructor(props) {
        super(props);
      }
    render() {
        const {children} = this.props;
        return (
            <form >
                {children}
            </form>
        )
    }
}

export default Form
