import React from 'react';
import Hyperlink from '../components/common/Hyperlink';

const FourOFour = () => {
  return (
    <div className="flex flex-col items-center h-screen">
      <h1 className="text-6xl font-bold text-gray-800 mt-10">
        404
      </h1>
      <h2 className="text-4xl">Page Not Found</h2>
      <div className="mt-2">
        <Hyperlink  to="/" >Go Back Home</Hyperlink>
      </div>
    </div>
  );
};

export default FourOFour;
