import React, { useContext } from 'react';
import { AuthContext } from './../context/AuthContext';
import Login from '../components/Login';

const Home = () => {
  const authContext = useContext(AuthContext);
  const {userInfo} = authContext;
  console.log(userInfo);
  return (
    <>
      <section className="entry-form dark-text">
        <Login ><h5>Welcome<br />Back</h5></Login>
      </section>
    </>
  );
};

export default Home;
