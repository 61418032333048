import React, { Component } from 'react'

export class FormValidComment extends Component {
    
    render() {
        const { show,children } = this.props;
        return (
            <>
            {show ? (
                <div className="FormValidComment">{children}</div>
            ) : (
                <></>
            ) }
            </>
        )
    }
}

export default FormValidComment
