import React from 'react';

class Input extends React.Component {

  render() {
    const { ariaLabel,name,placeholder,onChange,type,defaultValue,autoComplete} = this.props;
    // 
    return (  
      <>
      <input
        aria-label={ariaLabel}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        type={type}
        onChange={onChange}
        value={defaultValue}
      />
      </>
    );
  }
}

export default Input;