import React, {lazy,Suspense }  from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { AuthProvider} from './context/AuthContext';
import { FetchProvider } from './context/FetchContext';
import Loading from './components/Loading';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import FourOFour from './pages/FourOFour';
import Home from './pages/Home';
import ErrorBoundary from './components/ErrorBoundary';




const SignUpPage = lazy(() => import('./pages/SignupPage'));
const Dashboard = lazy(() => import('./pages/Dashboard'));


const AppRoutes = () => {
    return (
      <Suspense fallback={<Loading />} >
        <Switch>
          {/* <Route path="/login">
            <Login />
          </Route>*/}
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/sign-up">
            <SignUpPage />
          </Route> 
          <Route exact path="/">
            <Home />
          </Route>
          
          <Route path="*">
            <FourOFour />
          </Route>
        </Switch>
      </Suspense>
    );
};


function App() {
  return (
    <Router>
        <ErrorBoundary>
          <AuthProvider>
            <FetchProvider>
              <div className="page-wrapper">
                <Header />
                <div className="page-content">
                  <AppRoutes />
                </div>
                <Footer /> 
              </div>
            </FetchProvider>
          </AuthProvider>
        </ErrorBoundary>
    </Router>
  );
}

export default App;
