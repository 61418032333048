import React, { Component } from 'react';
import Input from './../common/Input';
import FormValidComment from './FormValidComment';

export class FormInput extends Component {
  render() {
    const { autoComplete,showLabel,ariaLabel,name,placeholder,onChange,type,valid,validMessage,defaultValue} = this.props;
    return (
      <>
        {showLabel ? (
        <div className="formLabel">
            {ariaLabel}
        </div>
        ) : (
            <></>
        ) }
        <div className="FormInput">
          <div className="form-input" >
              <Input
                ariaLabel={ariaLabel}
                name={name}
                type={type}
                autoComplete={autoComplete}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
              />
          </div>
          <FormValidComment 
                  show={valid}
                >{validMessage}</FormValidComment> 
        </div>
      </>
    )
  }
}

export default FormInput
