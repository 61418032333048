import React, { useState, useContext } from 'react';
import {
  Redirect,
} from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { publicFetch } from '../utils/fetch';
import Button from './common/Button';
import Form from './forms/Form'
import FormInput from './forms/FormInput';
import FormPassword from './forms/FormPassword';
import Hyperlink from './common/Hyperlink';



const Login = ({children,afterContent}) => {
  const authContext = useContext(AuthContext);
  const [showSuccess  , setShowSuccess] = useState(false);
  const [showError    , setShowError] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState();
  const [loginError, setLoginError] = useState();
  const [loginLoading, setLoginLoading] = useState(false);
  const [redirectOnLogin,setRedirectOnLogin] = useState(false);

  
  const [userdata,setUserdata] = useState({
    email: '',
    password: ''
  });


  const SubmitCredentials = async credentials => {
    try {
      setLoginLoading(true);
      const { data } = await publicFetch.post('authenticate', credentials);
      setLoginLoading(false);
      
      setLoginSuccess(data.message);
      setLoginError(null);
      setShowSuccess(true);
      setShowError(false);

      authContext.setAuthState(data);
      setTimeout( () => {
          setRedirectOnLogin(true);
      },700); 
    } catch (error) {
      setLoginLoading(false);
      
      const { data } = error.response;
      setLoginError(data.message);
      setLoginSuccess(null);
      setShowSuccess(false);
      setShowError(true);
    }
  };
  const DoContinue = e => {
    e.preventDefault();
    const data = SubmitCredentials(userdata);
    
    
  }

  const handleChange = e => {
    let thisData = {
      email : userdata.email,
      password : userdata.password
    }
    if (e.target.name === 'email') {
      thisData['email'] = e.target.value;
    }
    if (e.target.name === 'password') {
      thisData['password'] = e.target.value;
    }
    setUserdata(thisData);
  }


  return (
    <>
      {redirectOnLogin && <Redirect to="/dashboard" /> }
    <div  className="login ">
      <div className="preLogin">
          {children}
      </div>
      <div className="loginContent">
      <Form>
          <FormInput
              ariaLabel="Email"
              name="email"
              type="text"
              placeholder="Email"
              onChange={handleChange}
              defaultValue={userdata.email}
              autoComplete="username"
              
            />
          <FormPassword
              ariaLabel="Password"
              name="password"
              type="password"
              placeholder="Password"
              onChange={handleChange}
              defaultValue={userdata.password}
              valid={true}
              showValidation={false}
              
            />
          <div className="form-submit">
          <Button
              className="btn green-btn "
              type="submit"
              text="Log In"
              loading={loginLoading}
              onClick={DoContinue}
            />
          </div>
          {showSuccess ? (<div className="message loginSuccess">{loginSuccess}</div>) : (<></>)}    
          {showError ? (<div className="message setLoginError">{setLoginError}</div>) : (<></>)} 
            
          <div className="bottom-links" >
              <Hyperlink
                  className="login"
                  to="sign-up"
                >Sign Up</Hyperlink>
              <Hyperlink
                  className="login"
                  to="forgot-password"
                  text="Forgot your password?"
                >Forgot Password</Hyperlink>
          </div>
        </Form>
      </div>
      <div className="postLogin">
        {afterContent}
      </div>
    </div>
    </>
  );
};

export default Login;