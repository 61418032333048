import React, { Component } from 'react'

class FormPasswordStrength extends Component {
    constructor(props) {
        super(props);
        // This binding is necessary to make `this` work in the callback
        this.getClass = this.getClass.bind(this);
    }

    getClass = (option) => {
        let returnClass = option;
        const { values : {strengthObj}} = this.props;
        switch (option) {
            case 'length' :
                if(strengthObj.length) {
                    returnClass += ' check'; 
                } else {
                    returnClass += ' uncheck';
                }
            break;
            case 'upper_lower' :
                if(strengthObj.upper_lower) {
                    returnClass += ' check'; 
                } else {
                    returnClass += ' uncheck';
                }
            break;
            case 'digits' :
                if(strengthObj.digits) {
                    returnClass += ' check'; 
                } else {
                    returnClass += ' uncheck';
                }
            break;
            case 'nonWords' :
                if(strengthObj.nonWords) {
                    returnClass += ' check'; 
                } else {
                    returnClass += ' uncheck';
                }
            break;
            default: 
                return returnClass;
        }
        return returnClass;
    }

    render() {
        return (
            <>
                <div className="FormPasswordStrength" >
                    {/* */}
                    <div className={this.getClass('length')}>
                        Needs to be at least 8 characters long.
                    </div>
                    <div className={this.getClass('upper_lower')}>
                        Needs both lower and upper case characters 
                    </div>
                    <div className={this.getClass('digits')}>
                        Needs a numeric characters
                    </div>
                    <div className={this.getClass('nonWords')}>
                        Needs a special character.
                    </div>
                </div>

            </>  
            
        )
    }
}

export default FormPasswordStrength;

