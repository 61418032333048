import React, { useState } from 'react';
import Button from './common/Button';
import AuthDebugger from './AuthDebugger.js';

const Footer = () => {
  const [showAuthDebugger, setShowAuthDebugger] = useState(
    false
  );
  return (
    <footer  className="footer">
        <div className="mobile-container">
            <div className="ml-2">
                <Button
                text="Auth Debugger"
                
                onClick={() =>
                    setShowAuthDebugger(!showAuthDebugger)
                }
                />
            </div>
            <div className="mt-4">
                {showAuthDebugger && <AuthDebugger />}
            </div>
      </div>
    </footer>
  );
};

export default Footer;